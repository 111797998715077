/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'
import NextError from 'next/error'
import { useEffect } from 'react'
import { captureException } from '@/shared'

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string }
}) {
  useEffect(() => {
    captureException(error)
  }, [error])

  // TODO: Maybe this should be have the same styles of the layout
  return (
    // eslint-disable-next-line jsx-a11y/html-has-lang
    <html>
      <body>
        {/* This is the default Next.js error component but it doesn't allow omitting the statusCode property yet. */}
        <NextError statusCode={undefined as any} />
      </body>
    </html>
  )
}
